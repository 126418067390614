<template>
  <div class="top-arrow">
    <div class="top-arrow__arrow">
      <div class="top-arrow__arrow-part top-arrow__arrow-part--left"></div>
      <div class="top-arrow__arrow-part top-arrow__arrow-part--right"></div>
    </div>
    <div class="top-arrow__text">{{ $t('component.goToTop.text') }}</div>
  </div>
</template>

<style lang="scss" scoped>
@import '../styles/colors.scss';
.top-arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75em;
  }

  &__arrow {
    position: relative;
    left: -2pt;
    bottom: 50px;
    animation: arrowMove 3s infinite;
  }

  &__arrow-part {
    position: absolute;
    width: 4pt;
    height: 22pt;
    background-color: $ghostWhite;
    border-radius: 10px;

    &--left {
      transform: rotate(45deg);
      left: -7pt;
    }

    &--right {
      transform: rotate(-45deg);
      left: 7pt;
    }
  }
}

@keyframes arrowMove {
  0% {
    transform: translateY(0px);
  }
  5% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
