<template>
  <div class="shop-button__wrapper">
    <a class="shop-button__link" :class="{'shop-button__link--disabled': !$props.active}" href="https://google.com">
      <div :class="`shop-button shop-button--${$props.type}`" :aria-label="label[$props.type]" role="button"></div>
    </a>
    <div v-if="!$props.active" class="shop-button__overlay">
      <div class="shop-button__overlay-text">{{ $t('component.shopButton.commingSoon') }}</div>
    </div>
  </div>
</template>

<script>
import { i18n } from 'vue-lang-router'
export default {
  props: {
    type: String,
    active: Boolean
  },

  data() {
    return {
      label: {
        google: i18n.global.t('shopButton.googleLabel'),
        apple: i18n.global.t('shopButton.appleLabel')
      }
    }
  },

  created() {
    switch (this.$props.type) {
      case 'google':
        this.imgAlt = 'Google Play store button'
        break
      case 'apple':
        this.imgAlt = 'App Store store button'
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-button {
  width: 100%;
  padding-bottom: 29.5%;
  background-size: cover;
  background-repeat: no-repeat;

  &--google {
    background-image: url("~@/assets/stores-buttons/gplay.png");
  }

  &--apple {
    background-image: url("~@/assets/stores-buttons/appstore.png");
  }

  &__wrapper {
    position: relative;
    width: 180px;
  }

  &__link {
    display: block;

    &--disabled {
      pointer-events: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    backdrop-filter: grayscale(100%);
    background-color: rgba(0, 0, 0, 0.88);
  }

  &__overlay-text {
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.1em;
  }

  &__image {
    max-width: 100%;
  }
}
</style>
