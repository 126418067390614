<template>
  <div class="navbar">
    <div class="navbar__logo">
      <img src="@/assets/logo.png" alt="Tullu logo">
    </div>
    <div class="navbar__links">
      <div
        class="navbar__link"
        @click="$emit('pick', i)"
        v-for="(e, i) in elements"
        :key="i"
        :ref="addItemRef"
      >
        {{ e }}
      </div>
      <div
        class="navbar__indicator"
        :style="{ width: indicatorWidth, transform: `translateX(${indicatorOffset})` }"
      ></div>
    </div>
    <div style="width: 50px"></div>
  </div>
</template>

<script>
export default {
  props: {
    current: Number,
    elements: Array
  },

  watch: {
    current() {
      this.calculateIndicatorProps()
    }
  },

  data() {
    return {
      indicatorWidth: '0px',
      indicatorOffset: '0px',
      itemsRefs: []
    }
  },

  methods: {
    calculateIndicatorProps() {
      const el = this.itemsRefs[this.current]
      if (!el) return
      this.indicatorWidth = el.offsetWidth + 'px'
      this.indicatorOffset = el.offsetLeft + 'px'
    },

    addItemRef(item) {
      this.itemsRefs.push(item)
    }
  },

  mounted() {
    this.calculateIndicatorProps()
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 40px 0 40px;
  max-width: 100vw;

  &__logo {
    width: 55px;
    height: 55px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    flex-shrink: 0;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 60%;
    min-width: 525px;
    text-transform: uppercase;
    font-size: 1em;
  }

  &__link {
    cursor: pointer;
  }

  &__indicator {
    position: absolute;
    height: 3px;
    top: 22px;
    background-color: $ghostWhite;
    will-change: width;
    transition: width 0.2s ease, transform 0.2s ease;
    flex-shrink: 0;
  }
}

@media only screen and (max-device-width: 640px) {
  .navbar {
    padding: 0 20px;
    height: 66px;
    background-color: rgba(43, 61, 65, 0.65);
    backdrop-filter: blur(3px);

    &__links {
      display: none !important;
    }

    &__logo {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
