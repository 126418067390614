<template>
  <div class="scroll-down">
    <div class="scroll-down__text">{{ $t('component.scrollDown.text') }}</div>
    <div class="scroll-down__mouse">
      <div class="scroll-down__point"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../styles/colors.scss';
.scroll-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__text {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75em;
    margin-bottom: 15px;
  }

  &__mouse {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 26pt;
    height: 40pt;
    border: 4px solid $ghostWhite;
    border-radius: 30px;
  }

  &__point {
    width: 4pt;
    height: 7pt;
    border-radius: 5px;
    background-color: $ghostWhite;
    margin-top: 10px;
    animation: mousePointMove 1.5s infinite;
  }
}

@keyframes mousePointMove {
  0% {
    transform: translateY(0px);
  }
  5% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
