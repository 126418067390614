<template>
  <div
    class="slide-phones"
    :class="{ 'slide-phones--inverted': $props.inverted }"
  >
    <div
      v-for="(phone, i) in phones"
      :key="phone.alt"
      class="slide-phones__phone-wrapper"
      :style="{
        width: isMobile ? phone.mobile.width : phone.desktop.width,
        minWidth:
          (isMobile ? phone.mobile.minWidth : phone.desktop.minWidth) || 'none',
      }"
    >
      <div
        class="slide-phones__phone"
        :class="`slide-phones__phone--${phone.device}`"
        :style="{
          transform: `translate(${
            offsets[i][isMobile ? 'mobile' : 'desktop'].x
          }, ${offsets[i][isMobile ? 'mobile' : 'desktop'].y}) scale(${(isMobile ? phone.mobile.scale : phone.desktop.scale) || '1'})`,
          display:
            (isMobile ? phone.mobile.display : phone.desktop.display) ||
            'block',
          zIndex: (isMobile ? phone.mobile.zIndex : phone.desktop.zIndex) || 1,
          backgroundImage: `url(${phone.img})`,
        }"
      >
      <span role="img" :aria-label="phone.alt"> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '../../../scripts/helpers.js'
const defaultOffsets = {
  mobile: {
    x: 0,
    y: 0
  },
  desktop: {
    x: 0,
    y: 0
  }
}
export default {
  props: {
    phones: Array,
    inverted: Boolean
  },

  data() {
    return {
      isMobile: false,
      offsets: []
    }
  },

  methods: {
    pushOffsets(o) {
      this.offsets.push(o || defaultOffsets)
    },

    getDefaultOffsetsCopy() {
      return JSON.parse(JSON.stringify(defaultOffsets))
    }
  },

  created() {
    this.isMobile = isMobile()
    for (const p of this.$props.phones) {
      const o = this.getDefaultOffsetsCopy()
      if (p.mobile.offset) {
        if (p.mobile.offset.x) o.mobile.x = p.mobile.offset.x
        if (p.mobile.offset.y) o.mobile.y = p.mobile.offset.y
      }

      if (p.desktop.offset) {
        if (p.desktop.offset.x) o.desktop.x = p.desktop.offset.x
        if (p.desktop.offset.y) o.desktop.y = p.desktop.offset.y
      }
      this.pushOffsets(o)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-phones {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &--inverted {
    justify-content: flex-start;
  }

  &__phone {
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    &--ip8 {
      padding-bottom: 200.2727%;
    }

    &--ip12 {
      padding-bottom: 202.2674%;
    }
  }
}
</style>
