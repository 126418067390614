<template>
  <div class="home-slider" @mousewheel.passive="scrollHandler">
    <div
      class="home-slider__slides"
      :style="{ transform: `translateY(-${currentSlide * 100}vh)` }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slidesCount: 0,
      currentSlide: 0,
      scrollEventLock: false
    }
  },

  props: {
    scrollDelta: Number,
    scrollTimeout: Number
  },

  emits: ['slide'],

  methods: {
    setScrollLock() {
      this.scrollEventLock = true
      setTimeout(() => {
        this.scrollEventLock = false
      }, this.scrollTimeout)
    },

    scrollHandler(e) {
      if (this.scrollEventLock) return
      if (
        e.deltaY > 0 &&
        e.deltaY > this.scrollDelta &&
        this.currentSlide < this.$slots.default().length - 1
      ) {
        this.currentSlide++
        this.$emit('slide', this.currentSlide)
        this.setScrollLock()
      } else if (e.deltaY < 0 && e.deltaY < -this.scrollDelta && this.currentSlide > 0) {
        this.currentSlide--
        this.$emit('slide', this.currentSlide)
        this.setScrollLock()
      }
    },

    scrollTo(index) {
      if (index > this.$slots.default().length - 1 || index < 0) {
        return
      }
      this.currentSlide = index
      this.$emit('slide', this.currentSlide)
    }
  }
}
</script>

<style lang="scss">
.home-slider {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &__slides {
    width: 100%;
    position: absolute;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
  }

  &__slides > div {
    height: 100vh;
  }
}

@media only screen and (max-device-width: 640px) {
  .home-slider {
    overflow: auto;
  }
}
</style>
