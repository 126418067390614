<template>
  <div class="slide">
    <div
      class="slide__left"
      :style="{ 'justify-content': leftJustify, 'align-items': leftAlign }"
    >
      <slot name="left"></slot>
    </div>
    <div
      class="slide__right"
      :style="{ 'justify-content': rightJustify, 'align-items': rightAlign }"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftAlign: {
      type: String,
      default: 'center'
    },
    rightAlign: {
      type: String,
      default: 'center'
    },
    leftJustify: {
      type: String,
      default: 'center'
    },
    rightJustify: {
      type: String,
      default: 'center'
    }
  }
}
</script>

<style lang="scss" scoped>
.slide {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;

  &__left,
  &__right {
    display: flex;
    width: 50%;
    height: 100%;
  }

  &__left {
    z-index: 2;
  }
}
</style>
