<template>
  <div class="home">
    <Navbar
      class="home__navbar"
      :elements="tabs"
      :current="current"
      @pick="navbarClick"
    ></Navbar>
    <Particles
      class="home__particles"
      id="tsparticles"
      :options="{
        background: {
          color: {
            value: '#2b3d41',
          },
        },
        fpsLimit: 25,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            resize: true,
          },
        },
        particles: {
          color: {
            value: '#f7f7f7',
          },
          links: {
            color: '#f7f7f7',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 4,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }"
    />
    <HomeSlider
      class="home__slider"
      :scrollDelta="25"
      :scrollTimeout="1500"
      @slide="slideChange"
      ref="slider"
    >
      <SlideHome class="home__slide"></SlideHome>
      <SlideQuickEvents class="home__slide"></SlideQuickEvents>
      <SlidePeople class="home__slide"></SlidePeople>
      <SlideParties class="home__slide"></SlideParties>
      <SlidePartners class="home__slide"></SlidePartners>
      <SlideCoupons class="home__slide"></SlideCoupons>
    </HomeSlider>
    <div v-if="current < tabs.length - 1" class="home__scroll-mouse">
      <ScrollDownMouse @click="scrollDown"></ScrollDownMouse>
    </div>
    <div v-else class="home__scroll-mouse">
      <GoToTopArrow @click="scrollToTop"></GoToTopArrow>
    </div>
  </div>
</template>

<script>
import HomeSlider from '@/components/HomeSlider.vue'
import Navbar from '@/components/Navbar/Navbar.vue'
import ScrollDownMouse from '@/components/ScrollDownMouse.vue'
import GoToTopArrow from '@/components/GoToTopArrow.vue'
import SlideHome from '@/components/HomeSlides/SlideHome.vue'
import SlideQuickEvents from '@/components/HomeSlides/SlideQuickEvents.vue'
import SlidePeople from '@/components/HomeSlides/SlidePeople.vue'
import SlideParties from '@/components/HomeSlides/SlideParties.vue'
import SlidePartners from '@/components/HomeSlides/SlidePartners.vue'
import SlideCoupons from '@/components/HomeSlides/SlideCoupons.vue'

export default {
  name: 'Home',
  components: {
    HomeSlider,
    Navbar,
    ScrollDownMouse,
    GoToTopArrow,
    SlideHome,
    SlideQuickEvents,
    SlidePeople,
    SlideParties,
    SlidePartners,
    SlideCoupons
  },

  data() {
    return {
      current: 0,
      tabs: [
        this.$t('component.homeSlide.home.navTitle'),
        this.$t('component.homeSlide.quickEvents.navTitle'),
        this.$t('component.homeSlide.people.navTitle'),
        this.$t('component.homeSlide.events.navTitle'),
        this.$t('component.homeSlide.partners.navTitle'),
        this.$t('component.homeSlide.coupons.navTitle')
      ]
    }
  },

  methods: {
    slideChange(slide) {
      this.current = slide
    },

    navbarClick(n) {
      this.current = n
      this.$refs.slider.scrollTo(n)
    },

    scrollDown() {
      this.$refs.slider.scrollTo(this.current + 1)
    },

    scrollToTop() {
      this.$refs.slider.scrollTo(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";
.home {
  position: relative;
  background-color: $background;
  height: 100vh;

  &__navbar {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &__slider {
    position: absolute;
    top: 0;
    background-color: transparent;
  }

  &__slide {
    &:nth-of-type(2n) {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__particles {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
  }

  &__scroll-mouse {
    width: 100vw;
    position: absolute;
    bottom: 5vh;
  }
}

@media only screen and (max-device-width: 640px) {
  .home {
    &__scroll-mouse {
      display: none;
    }
  }
}
</style>
