<template>
  <div class="slide-text">
    <div class="slide-text__title">
      <slot name="title"></slot>
    </div>
    <div class="slide-text__text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss">
@import "../../../styles/colors.scss";

.slide-text {
  width: 80%;
  margin: 0 auto;
  z-index: 2;

  &__title {
    display: flex;

    & > * {
      display: flex;
      font-weight: 700;
      border-bottom: 14px solid $tulluColor;
      margin: 0 0 30px 0;
    }

    & > h1 {
      font-size: 6em;
    }

    & > h2 {
      font-size: 4em;
    }
  }

  &__text {
    & > p {
      margin-top: 6px;
      font-size: 1.5em;
      white-space: pre-line;
    }
  }
}

@media only screen and (max-device-width: 640px) {
  .slide-text {
    min-width: 290px;
    margin-left: 20px;

    &__title {
      font-size: 10px;

      & > * {
        border-bottom: 8px solid $tulluColor;
      }
    }

    &__text {
      font-size: 12px;
    }
  }
}
</style>
