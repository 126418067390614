<template>
  <slide
    rightAlign="flex-end"
    :leftJustify="isMobile() ? 'flex-start' : 'center'"
  >
    <template v-slot:left>
      <div>
        <slide-text>
          <template v-slot:title>
            <h2>{{ $t("component.homeSlide.people.title") }}</h2>
          </template>
          <template v-slot:text>
            <p>{{ $t("component.homeSlide.people.text") }}</p>
          </template>
        </slide-text>
      </div>
    </template>
    <template v-slot:right>
      <slide-phones :phones="phones"></slide-phones>
    </template>
  </slide>
</template>

<script>
import { i18n } from 'vue-lang-router'
import { isMobile } from '../../scripts/helpers.js'
import Slide from './helpers/SlideBase.vue'
import SlideText from './helpers/SlideText.vue'
import SlidePhones from './helpers/SlidePhones.vue'

const loc = i18n.global.locale

export default {
  components: {
    Slide,
    SlideText,
    SlidePhones
  },

  data() {
    return {
      isMobile,
      phones: [
        {
          img: require(`@/assets/phones/${loc}/people.webp`),
          alt: i18n.global.t('imageAlt.phone.people'),
          device: 'ip12',
          desktop: {
            width: '52%',
            minWidth: '260px',
            offset: {
              x: '30%',
              y: '10%'
            }
          },
          mobile: {
            width: '100%',
            scale: 1.6,
            offset: {
              x: '48%',
              y: '12%'
            }
          }
        },
        {
          img: require(`@/assets/phones/${loc}/profile.webp`),
          alt: i18n.global.t('imageAlt.phone.profile'),
          device: 'ip8',
          desktop: {
            width: '45%',
            minWidth: '260px',
            zIndex: 2,
            offset: {
              y: '35%'
            }
          },
          mobile: {
            width: '100%',
            display: 'none'
          }
        }
      ]
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
</style>
