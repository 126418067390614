<template>
  <slide
    class="slide-home"
    rightAlign="flex-end"
    :leftJustify="isMobile() ? 'flex-start' : 'center'"
  >
    <template v-slot:left>
      <div>
        <slide-text>
          <template v-slot:title>
            <h1>{{ $t("component.homeSlide.home.title") }}</h1>
          </template>
          <template v-slot:text>
            <p>{{ $t("component.homeSlide.home.text") }}</p>
          </template>
        </slide-text>
        <div class="slide-home__buttons">
          <shop-button
            class="slide-home__button"
            v-if="showGPlay"
            type="google"
            :active="false"
          ></shop-button>
          <shop-button
            class="slide-home__button"
            v-if="showAppStore"
            type="apple"
            :active="false"
          ></shop-button>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <slide-phones :phones="phones"></slide-phones>
    </template>
  </slide>
</template>

<script>
import { i18n } from 'vue-lang-router'
import { isMobile, isIOS } from '../../scripts/helpers.js'
import ShopButton from '../ShopButton.vue'
import Slide from './helpers/SlideBase.vue'
import SlidePhones from './helpers/SlidePhones.vue'
import SlideText from './helpers/SlideText.vue'

const loc = i18n.global.locale

export default {
  components: {
    ShopButton,
    Slide,
    SlidePhones,
    SlideText
  },

  data() {
    return {
      isMobile,
      showGPlay: true,
      showAppStore: true,
      phones: [
        {
          img: require(`@/assets/phones/${loc}/map.webp`),
          alt: i18n.global.t('imageAlt.phone.map'),
          device: 'ip12',
          desktop: {
            width: '52%',
            minWidth: '260px',
            offset: {
              x: '30%',
              y: '10%'
            }
          },
          mobile: {
            width: '100%',
            scale: 1.6,
            offset: {
              x: '48%',
              y: '12%'
            }
          }
        },
        {
          img: require(`@/assets/phones/${loc}/chats.webp`),
          alt: i18n.global.t('imageAlt.phone.chats'),
          device: 'ip8',
          desktop: {
            width: '52%',
            minWidth: '260px',
            zIndex: 2,
            offset: {
              y: '35%'
            }
          },
          mobile: {
            width: '100%',
            display: 'none'
          }
        }
      ]
    }
  },

  mounted() {
    if (isMobile()) {
      if (isIOS()) {
        this.showGPlay = false
      } else {
        this.showAppStore = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-home {
  &__buttons {
    min-width: 380px;
    width: 55%;
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;
    margin-left: 10%;
  }

  &__button:not(:first-of-type) {
    margin-left: 15px;
  }
}

@media only screen and (max-device-width: 640px) {
  .slide-home {
    &__buttons {
      flex-direction: column;
      margin-top: 30px;
      margin-left: 20px;

      & > * {
        margin: 10px 0;
        min-width: 170px;
      }
    }

    &__button {
      width: 140px;
    }
  }
}
</style>
